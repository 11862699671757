// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  VariableContent,
  ModalWithState,
  Image,
  Column,
  SVGImage,
  PackageCard,
  Brandy,
  Stack,
  SplitContent,
  useMapi,
  Link,
  ItemBank,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Columns, Typography } from '@leshen/ui'
import { graphql, navigate, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'
import SeoCtaBar from '../components/SeoCtaBar'

const StateTemplate = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges.map((current) => ({
    label: current.node.City,
    value: current.node.Page_Path,
  }))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            imageToBottom
            image={
              <Image
                data={data.heroDesktop.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.heroMobile.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  Quantum Fiber Internet<sup>™</sup> in {State_Name}
                </Typography>
                <Typography>
                  Learn about the Quantum Fiber network in your state or check
                  fiber internet availability near your ZIP Code.
                </Typography>
                <AvailabilityForm
                  label="Check Availability Near Me"
                  placeholderText="ZIP Code"
                  buttonText="View Plans"
                  buttonColor="primary"
                />
              </>
            }
          />
          <SeoCtaBar />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Quantum Fiber Internet Plans
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor="light"
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                modal={
                  <ModalWithState
                    modalText="Offer Details"
                    heading={data?.modalData?.heading}
                  >
                    <Brandy
                      key={data.package1Modal1.reference}
                      text={data.package1Modal1.text}
                      variant="legal"
                    />
                    <Brandy
                      key={data.package1Modal2.reference}
                      text={data.package1Modal2.text}
                      variant="legal"
                    />
                  </ModalWithState>
                }
              />

              <PackageCard
                label={false}
                packageData={data.package2}
                modal={
                  <ModalWithState
                    modalText="Offer Details"
                    heading={data?.modalData?.heading}
                  >
                    <Brandy
                      key={data.package2Modal1.reference}
                      text={data.package2Modal2.text}
                      variant="legal"
                    />
                  </ModalWithState>
                }
              />
            </Columns>
          </VariableContent>
          <SplitContent
            style={{ paddingBottom: '16px' }}
            backgroundColor=""
            alignImageToBottom={false}
            image={
              <Image data={data.familyDesktop.cloudinary[0].gatsbyImageData} />
            }
            mobileImage={
              <Image data={data.familyMobile.cloudinary[0].gatsbyImageData} />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Quantum Fiber brings next-gen internet service to {State_Name}
                </Typography>
                <Typography variant="h4">
                  Fiber speeds up to 940 Mbps.
                </Typography>
                <Typography>
                  Quantum Fiber Internet elevates the digital landscape in{' '}
                  {State_Name}. With speeds up to 940 Mbps on the Quantum Fiber
                  internet fiber network, {State_Name} homes like yours will
                  have the bandwidth for what you’ve always done online—and what
                  you might want to add, such as 4K streaming or VR games.
                </Typography>
                <Brandy
                  key={data.disclaimerProximitySpeed.reference}
                  text={data.disclaimerProximitySpeed.text}
                  variant="legal"
                />
                <Typography>
                  Find Quantum Fiber Internet plans in your city:
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={<></>}
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            breadcrumbProps={null}
            customLink={null}
            style={{ paddingTop: '0px' }}
          >
            <ItemBank>
              {cityDropdownData?.map((current) => (
                <a
                  href={decodeURIComponent(current?.value).replace(' ', '-')}
                  key={current.value}
                >
                  {current.label}
                </a>
              ))}
            </ItemBank>
          </VariableContent>
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Power your lifestyle with Quantum Fiber Internet
                </Typography>
                <Typography variant="h4">
                  Ways to use your brand-new Quantum Fiber Internet connection.
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor="light"
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column
                backgroundColor="white"
                image={
                  <SVGImage
                    svg={data.cardController.cloudinary[0].svg.code}
                    presentationWidth={
                      data.cardController.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Gaming</Typography>
                <Typography variant="">
                  It’s game over for data hiccups.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                image={
                  <SVGImage
                    svg={data.cardComputer.cloudinary[0].svg.code}
                    presentationWidth={
                      data.cardComputer.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Streaming</Typography>
                <Typography variant="">
                  Everyone grabs a screen and no one fights.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                image={
                  <SVGImage
                    svg={data.cardEnvelope.cloudinary[0].svg.code}
                    presentationWidth={
                      data.cardEnvelope.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Working</Typography>
                <Typography variant="">
                  Put on the headphones and focus.
                </Typography>
              </Column>
              <Column
                backgroundColor="white"
                image={
                  <SVGImage
                    svg={data.cardLaptop.cloudinary[0].svg.code}
                    presentationWidth={
                      data.cardLaptop.cloudinary[0].svg.presentationWidth
                    }
                  />
                }
                svg={false}
              >
                <Typography variant="h4">Studying</Typography>
                <Typography variant="">
                  Pause, rewind, listen in double time.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <VariableContent
            mainContent={
              <Typography variant="h2">Frequently Asked Questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion
                items={[
                  {
                    title: `How does the installation process work for Quantum Fiber internet service?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          When you order online or by phone at{' '}
                          <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>,
                          we’ll prompt you to select an available date and time
                          window for professional installation. On the day of
                          service, your technician will take a few minutes to
                          install your home’s first fiber hookup, connect it to
                          the Quantum internet network, and answer your
                          questions before leaving you with the internet speed
                          of your dreams.
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `Is fiber internet available in my area?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Quantum Fiber is available in 16 states and expanding
                          rapidly. To confirm fiber internet availability at
                          your address,{' '}
                          <Link to="/availability">
                            enter your ZIP code here
                          </Link>
                          .
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `What areas of the state are covered by Quantum Fiber?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Since offers vary, exact fiber internet availability
                          depends on your address.{' '}
                          <Link to="/availability">
                            enter your ZIP code here
                          </Link>{' '}
                          to see pricing and speeds for fiber internet near you.
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `What states are covered by Quantum Fiber?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Quantum Fiber covers states in the West, the Midwest,
                          and the South. For the most up-to-date coverage,{' '}
                          <Link to="/availability#availability-map">
                            view our availability map
                          </Link>
                          .
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `Is Quantum Fiber internet available in my state?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          <Link to="/availability#availability-map">
                            Check our availability map
                          </Link>
                          and enter your ZIP code to see if Quantum Fiber has
                          arrived in your state.
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: `What speed options are available in my state for Quantum Fiber?`,
                    content: (
                      <div>
                        <Typography variant="feature">
                          Quantum Fiber Internet offers speeds up to 500 Mbps or
                          up to 940 Mbps based on availability.
                        </Typography>
                        <Brandy
                          key={data.package1Modal1.reference}
                          text={data.package1Modal1.text}
                          variant="legal"
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </Stack>
          </VariableContent>
        </>
      }
      // footer={<Footer disclaimers={[data?.disclaimerFooter1]} />}
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetquantumfiberAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    cities: allDatasetManagerGetquantumfiberAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    heroDesktop: contentfulMedia(
      contentful_id: { eq: "6yvb7JUUq5OpqozwSZw6u1" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroMobile: contentfulMedia(
      contentful_id: { eq: "AH27dkbJ5Y11QraRwT2al" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    familyDesktop: contentfulMedia(
      contentful_id: { eq: "3IHk8AOjSITDNqmh3h5MQ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 976)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    familyMobile: contentfulMedia(
      contentful_id: { eq: "5it9NASveEKRBZyKfJo9Yf" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 570)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    cardController: contentfulMedia(
      contentful_id: { eq: "6kyjsHsQmOVEbu7N656p3z" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardComputer: contentfulMedia(
      contentful_id: { eq: "7tyvYCDxN2oPq9EMxIiZoy" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardEnvelope: contentfulMedia(
      contentful_id: { eq: "6cCP0QLK8nWlGdgKZyD06L" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    cardLaptop: contentfulMedia(
      contentful_id: { eq: "6hEpcqPFAkhChoOuHyJ5LA" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    package1: brandyPackage(brandy_id: { eq: "qf-internet-500" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "qf-internet-940" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1Modal1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-terms-qf-940-header" }
    ) {
      id
      text
      symbol
    }
    package1Modal2: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-terms-qf-940-1" }
    ) {
      id
      text
      symbol
    }
    package2Modal1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-terms-qf-940-header" }
    ) {
      id
      text
      symbol
    }
    package2Modal2: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-terms-qf-940-1" }
    ) {
      id
      text
      symbol
    }
    disclaimerProximitySpeed: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-proximity-speed" }
    ) {
      id
      text
      symbol
    }
    disclaimerFooter1: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-qf-allrightsreserved" }
    ) {
      id
      text
      symbol
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "87ee343a-7023-5a89-a4cb-0790ad2ab2e7" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "acff8862-7da3-5e17-9f83-5a564d73a494" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
